<template>
  <div>
    <div class="modal-body">
      <div
        class="form-group"
        :class="{ 'is-invalid': errors.has('password_current') }"
      >
        <label for="password_current" class="control-label"
          >Current password</label
        >
        <input
          type="password"
          name="password_current"
          v-model="form.password_current"
          v-validate="{ required: true }"
          id="password_current"
          class="form-control"
        />
        <span
          v-show="errors.has('password_current')"
          class="invalid-feedback"
          >{{ errors.first("password_current") }}</span
        >
      </div>
      <div class="form-row" :class="{ 'is-invalid': errors.has('password') }">
        <div class="form-group col-md-6">
          <label for="password" class="control-label">New password</label>
          <input
            type="password"
            name="password"
            v-model="form.password"
            v-validate="{ required: true }"
            id="password"
            class="form-control"
          />
          <span v-show="errors.has('password')" class="invalid-feedback">{{
            errors.first("password")
          }}</span>
        </div>
        <div
          class="form-group col-md-6"
          :class="{ 'is-invalid': errors.has('password_confirmation') }"
        >
          <label for="password_confirmation" class="control-label"
            >Confirm new password</label
          >
          <input
            type="password"
            name="password_confirmation"
            v-model="form.password_confirmation"
            v-validate="{ required: true }"
            id="password_confirmation"
            class="form-control"
          />
          <span
            v-show="errors.has('password_confirmation')"
            class="invalid-feedback"
            >{{ errors.first("password_confirmation") }}</span
          >
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="form-group col-12 mb-0">
        <ButtonLoading
          :loading="loading"
          :title="'Change password'"
          :class="'btn btn-secondary float-right'"
          v-on:click.native="submitForm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import jsonAPI from "@/api";
import ButtonLoading from "./ButtonLoading.vue";

export default {
  name: "PasswordForm",
  components: { 
    ButtonLoading,
  },
  data: function() {
    return {
      loading: false,
      form: {
        password_current: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .post("/user/password", this.form)
            .then(() => {
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Password hanged",
                type: "success",
              });
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "Could not update password",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
