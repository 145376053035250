<template>
  <div>
    <canvas ref="canvas" />
  </div>
</template>

<script>
import jsonAPI from "@/api";
import Chart from "@/utils/chart";

export default {
  name: "AutomationUsage",
  data() {
    return {
      count: 0,
      data: [],
      labels: [],
    };
  },
  mounted() {
    jsonAPI.get("organization/usage").then((response) => {
      this.data = Object.values(response.data.usage);
      const limit = Object.values(response.data.limit);
      this.labels = Object.keys(response.data.usage);
      const today = this.labels
        .slice(this.labels.length - 1, this.labels.length)
        .shift();
      const suggestedMax = response.data.usage[today] + 50;
      new Chart(this.$refs.canvas, {
        type: "line",
        data: {
          labels: this.labels,
          datasets: [
            {
              label: "Accumulated usage since renewal",
              data: this.data,
              borderWidth: 1,
            },
            {
              label: "Current plan limit",
              data: limit,
              borderWidth: 2,
              borderColor: "#8B0000",
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  suggestedMax: suggestedMax,
                },
                plotLines: [
                  {
                    color: "#FF0000",
                    width: 2,
                    value: 0.5 * 200, // The marker value will be 100
                    // Or you can just set its value to 100
                  },
                ],
              },
            ],
          },
        },
      });
    });
  },
};
</script>

<style scoped></style>
