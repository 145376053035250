<template>
  <component :is="layout">
    <prompt :active="isModalVisible" @close="isModalVisible = false">
      <h3 slot="header">
        Change password
      </h3>
      <div slot="content">
        <password-form />
      </div>
    </prompt>
    <div class="row fadeInUp" role="main">
      <div class="col-12 col-md-3">

      </div>
      <div class="col-12 col-md-9">
        <div class="card border-0 shadow-sm mb-4">
          <div class="card-header">
            <h4>Account</h4>
          </div>
          <div class="card-body">
            <div class="form-row">
              <div
                class="form-group col-md-6 required"
                :class="{ 'is-invalid': errors.has('name') }"
              >
                <label for="name" class="control-label text-capitalize"
                  >Name</label
                >
                <input
                  id="name"
                  v-model="account.name"
                  v-validate="{ required: true }"
                  type="text"
                  name="name"
                  class="form-control"
                />
                <span v-show="errors.has('name')" class="invalid-feedback">{{
                  errors.first("name")
                }}</span>
              </div>
              <div
                class="form-group col-md-6"
                :class="{ 'is-invalid': errors.has('title') }"
              >
                <label for="title" class="control-label text-capitalize"
                  >Title</label
                >
                <input
                  id="title"
                  v-model="account.title"
                  v-validate="{ required: false }"
                  type="text"
                  name="title"
                  class="form-control"
                />
                <span v-show="errors.has('title')" class="invalid-feedback">{{
                  errors.first("title")
                }}</span>
              </div>
              <div
                class="form-group col-md-6"
                :class="{ 'is-invalid': errors.has('telephone') }"
              >
                <label for="telephone" class="control-label text-capitalize"
                  >Telephone</label
                >
                <input
                  id="telephone"
                  v-model="account.telephone"
                  type="text"
                  name="telephone"
                  class="form-control"
                  placeholder="+4523632303"
                />
                <span
                  v-show="errors.has('telephone')"
                  class="invalid-feedback"
                  >{{ errors.first("telephone") }}</span
                >
              </div>
              <div
                class="form-group col-md-6 required"
                :class="{ 'is-invalid': errors.has('email') }"
              >
                <label for="email" class="control-label">Email</label>
                <input
                  id="email"
                  v-model="account.email"
                  v-validate="{ required: true, email: true }"
                  type="email"
                  name="email"
                  class="form-control"
                />
                <span v-show="errors.has('email')" class="invalid-feedback">{{
                  errors.first("email")
                }}</span>
              </div>
              <div class="form-group col-md-6">
                <label for="fake_password" class="control-label"
                  >Password</label
                >
                <div class="input-group mb-3">
                  <input
                    id="fake_password"
                    type="password"
                    name="password"
                    class="form-control"
                    disabled
                    value="randompassword"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary d-block"
                      @click="isModalVisible = true"
                    >
                      change
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <ButtonLoading
              :loading="loading"
              :title="'Save settings'"
              :class="'btn btn-secondary float-right'"
              v-on:click.native="submitForm"
            />
          </div>
        </div>
        <p class="text-muted">
          Not using you're account?
          <router-link :to="{ name: 'account.deactivate' }" class="text-red">
            Deactivate account
          </router-link>
        </p>
      </div>
    </div>
  </component>
</template>

<script>
import jsonAPI from "@/api";
import Default from "@/layouts/default.vue";
import Prompt from "@/components/UI/Prompt.vue";
import PasswordForm from "@/components/Form/PasswordForm.vue";
import OrganizationNav from "@/components/Nav/OrganizationNav.vue";
import AutomationUsage from "@/components/Account/AutomationUsage.vue";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  components: {
    Default,
    Prompt,
    PasswordForm,
    OrganizationNav,
    AutomationUsage,
    ButtonLoading,
  },
  beforeRouteEnter(to, from, next) {
    jsonAPI.get("user/current").then((response) => {
      next((vm) => (vm.account = response.data));
    });
  },
  data: function() {
    return {
      layout: "Default",
      isModalVisible: false,
      loading: false,
      account: {
        name: null,
        email: null,
        telephone: null,
        organization: {
          title: null,
          members: 0,
        },
      },
    };
  },
  methods: {
    submitForm: function() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          jsonAPI
            .put("/user/" + this.account.id, this.account)
            .then(() => {
              window.Bus.$emit("flash-message", {
                text: "account updated",
                type: "success",
              });
              this.loading = false;
            })
            .catch((error) => {
              Object.keys(error.response.data.errors).forEach((key) => {
                this.$validator.errors.add({
                  field: key,
                  msg: error.response.data.errors[key][0],
                });
              });
              this.loading = false;
              window.Bus.$emit("flash-message", {
                text: "error main",
                type: "error",
              });
            });
        } else {
          this.loading = false;
        }
      });
    },
  },
};
</script>
